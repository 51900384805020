import { NoticeContainer } from './NoticeContainer.styled';

import { Header } from './Header.styled';
import { ButtonContainer } from './ButtonContainer.styled';

import { TextContainer } from './TextContainer.styled';
import type { FC, ReactNode } from 'react';
import type { Color, Size } from 'styled/types';

export interface NoticeProps {
  color?: Color;
  background?: Color;
  header?: string;
  subHeader?: ReactNode;
  button: ReactNode;
  wrapMobile?: 'soft' | false;
  padding?: Size;
}

export const Notice: FC<NoticeProps> = ({
  header,
  subHeader,
  button,
  background,
  color,
  padding = 8,
  wrapMobile = 'soft',
}: NoticeProps) => {
  let defaultedColor = color;
  if (!color && !background) defaultedColor = 'callout';
  if (!header && !subHeader) return null;

  return (
    <NoticeContainer
      containerAs="aside"
      color={defaultedColor}
      background={background}
      wrapMobile={wrapMobile}
      padding={padding}
    >
      {(header || subHeader) && (
        <TextContainer>
          {header && <Header>{header}</Header>}
          {subHeader && <div>{subHeader}</div>}
        </TextContainer>
      )}
      {button && <ButtonContainer>{button}</ButtonContainer>}
    </NoticeContainer>
  );
};
