import { SignupLink } from 'components/Links';
import { Notice } from './Notice';

import type { FC } from 'react';
import type { Size } from 'styled/types';

export const CtaHoldingsNotice: FC<{ padding?: Size }> = ({ padding }) => (
  <Notice
    padding={padding}
    background="callout"
    header="Start by tracking up to 10 holdings for free"
    subHeader="Upgrade any time to track more holdings and unlock advanced features."
    button={<SignupLink asButton />}
  />
);
