import { useContentfulLocale } from 'hooks/useContentfulLocale';

import { Layout } from 'components/Layout';
import { Seo } from 'components/Seo';
import { AppLink, ExternalLink, GatsbyLink } from 'components/Links';
import { Cards } from 'components/Cards';
import { TrustPilot } from 'components/TrustPilot';
import { ImageCards } from 'components/ImageCard';
import { Box } from 'components/Box';
import { Notice } from 'components/Notice';
import { Container, HeroContainer, Section, SectionImage } from 'components/Container';
import { usePartnerCards, partnerTypeCards } from 'page-data/become-a-partner';

import HeroImage from 'images/become-a-partner/hero.inline.svg';
import SharesightProduct from 'images/become-a-partner/product-on-devices.inline.svg';

import type { FC } from 'react';
import type { LocalizedPageProps } from '@sharesight/gatsby-plugin-sharesight-localization';

export const partnersFormLink =
  'https://docs.google.com/forms/d/e/1FAIpQLSckS8pCTJWt_Ox1R_t0EDic_NMTg4Kc2JOPKHs6wBvBlDq_fw/viewform';

export const BecomeAPartner: FC<LocalizedPageProps> = () => {
  const {
    translations: { freeUsers },
  } = useContentfulLocale();

  const partnerCards = usePartnerCards();

  return (
    <Layout>
      <Seo />

      <HeroContainer>
        <Section>
          <h1>Partner with Sharesight for growth</h1>
          <p>Access award-winning portfolio performance and tax reporting</p>

          <br />
          <br />

          <ExternalLink asButton to={partnersFormLink} appearance="primary">
            Become a partner
          </ExternalLink>
        </Section>

        <SectionImage image={<HeroImage />} boxShadow={false} />
      </HeroContainer>

      <Container manageLayout={false}>
        <h2>A connected experience, with data-intelligence for business growth</h2>
        <p>
          Sharesight believes in building a connected ecosystem of solutions that puts investors in
          control. Partners work with Sharesight to extend their product functionality, to leverage
          our <AppLink to="/api">API</AppLink> for performance and tax reporting, and to gain
          exposure to our fast growing universe of experienced self-directed investors, financial
          advisors, family offices, and accounting firms.
        </p>
        <p>
          We work collaboratively with our partners to solve problems across the financial,
          technology and marketing spectrum. Our partnerships take on many forms. Sharesight can be
          a supplemental add-on, or a core application.
        </p>
      </Container>

      <Container manageLayout={false}>
        <Box marginBottom={11}>
          <center>
            <h2>Ways to partner with Sharesight</h2>
            <p>
              Typically our partners fall into two main categories;{' '}
              <GatsbyLink to="/professionals" appearance="secondary">
                accountants and financial advisers
              </GatsbyLink>{' '}
              who benefit from Sharesight to minimise admin and maximise insights to clients, and{' '}
              <GatsbyLink to="/api-technology-partners" appearance="secondary">
                API technology partners
              </GatsbyLink>{' '}
              who integrate with Sharesight to leverage data intelligence and connected audiences.
            </p>
          </center>
        </Box>
        <ImageCards cards={partnerTypeCards} />
      </Container>

      <Cards
        cards={partnerCards}
        header="Why partner with us?"
        subHeader={`Partners work with Sharesight to extend their product functionality, to leverage our API for performance and tax reporting, and to gain exposure to our fast growing universe of more than ${freeUsers} experienced, self-directed investors.`}
      />

      <Container>
        <Section>
          <h2>Sharesight integration makes insightful financial transactions simple</h2>
          <p>
            The <AppLink to="/api/3/overview">Sharesight API</AppLink> allows you to integrate your
            platform or data with Sharesight’s online portfolio management software. Access is
            permissioned on a per-user level.
          </p>
        </Section>
        <SectionImage boxShadow={false} image={<SharesightProduct />} />
      </Container>
      <Notice
        padding={10}
        header="Become a Sharesight partner"
        button={
          <ExternalLink to={partnersFormLink} asButton appearance="primary">
            Apply now
          </ExternalLink>
        }
        background="callout"
      />

      <TrustPilot />
    </Layout>
  );
};

export default BecomeAPartner;
