import styled, { css } from 'styled-components';

import { Container } from 'components/Container';

export const NoticeContainer = styled(Container)`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
  }

  > * {
    display: flex;

    ${props =>
      props.wrapMobile &&
      css`
        ${({ theme }) => theme.breakpoints.down('md')} {
          flex-direction: column;
        }
      `}
  }
`;
