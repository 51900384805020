import { SignupLink } from 'components/Links';
import { Notice } from './Notice';

export const ProCTANotice = () => (
  <Notice
    background="callout"
    header="Try Sharesight Pro today"
    subHeader="Get a free trial to start tracking your clients' portfolios"
    button={<SignupLink asButton>Start your free trial</SignupLink>}
  />
);
